<template>
  <router-view></router-view>
</template>

<script setup>
import { useAuth } from '@/state/modules/auth';

// import { useHardLists } from '@/state/modules/hardLists';

const auth = useAuth();
auth.loadApp();

</script>
