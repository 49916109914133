import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { useAuth } from '@/state/modules/auth';

export const HOST = process.env.VUE_APP_URL;

export const API = axios.create({
  baseURL: `${HOST}`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

API.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.config.url === "/auth/refresh") {
      const auth = useAuth();
      auth.DestructToken();
    }
    return Promise.reject(error);
  }
);
const refreshAuthLogic = async (failedRequest) => {
  const auth = useAuth();
  return auth.refreshToken().then((token) => {
    failedRequest.response.config.headers.Authorization = `Bearer ${token}`;
    return Promise.resolve();
  });
};

createAuthRefreshInterceptor(API, refreshAuthLogic, {
  pauseInstanceWhileRefreshing: true,
});
